<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="100px" style="overflow-x:hidden;overflow-y:auto;">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性別" prop="sex">
            <el-select v-model="form.sex" placeholder="请选择性别" style="width:100%">
              <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="出生日期" prop="birthDay">
            <el-date-picker
              v-model="form.birthDay"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="请选择出生日期"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="身份证号" prop="identityCards">
            <el-input v-model="form.identityCards" placeholder="请输入身份证号" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="学历" prop="education">
            <el-input v-model="form.education" placeholder="请输入学历" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所属单位">
            <el-input v-model="form.affiliatedUnit" placeholder="请输入所属单位" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="联系方式" prop="contTel">
            <el-input v-model="form.contTel" placeholder="请输入联系方式" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="职称" prop="jobTitle">
            <el-input v-model="form.jobTitle" placeholder="请输入职称" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="从业年限" prop="workYears">
            <el-input v-model="form.workYears" placeholder="请输入从业年限" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="从业资质">
            <el-input v-model="form.professionalQualification" placeholder="请输入从业资质" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务站点" prop="serviceSiteId">
            <el-input v-model="form.serviceSiteId" placeholder="请选择服务站点" auto-complete="off" :disabled="true">
              <el-button type="primary" slot="append" icon="el-icon-search" @click="selectServiceSiteId()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="7">
          <el-form-item label="地址" prop="city">
            <el-select v-model="form.city" @change="cityChange" placeholder="请选择所属城市">
              <el-option v-for="item in cityList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="area">
            <el-select v-model="form.area" @change="areaChange" placeholder="请选择所属区域">
              <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="street">
            <el-select v-model="form.street" @change="streetChange" placeholder="请选择所属街道">
              <el-option v-for="item in streetList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="community">
            <el-select v-model="form.community" placeholder="请选择所属社区">
              <el-option v-for="item in communityList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="0" prop="address">
            <el-input v-model="form.address" placeholder="请输入详细地址" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="健康证" prop="healthReport">
            <upload-img :limit="1" :uploadLists="healthReportList" @uploadChange="uploadSuccess1"></upload-img>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="核酸检测证明" prop="testCertificate">
            <upload-img :limit="1" :uploadLists="testCertificateList" @uploadChange="uploadSuccess2"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="合同扫描件" prop="scannedContract">
            <upload-img :limit="1" :uploadLists="scannedContractList" @uploadChange="uploadSuccess3"></upload-img>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="保险单" prop="insurancePolicy">
            <upload-img :limit="1" :uploadLists="insurancePolicyList" @uploadChange="uploadSuccess4"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择服务站点" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
        <div>
          服务站点名称：
          <el-input style="width:200px;margin-right:10px;" size="medium" v-model="searchData.cmSiteName" placeholder="请输入服务站点名称"></el-input>
          <el-button size="medium" type="primary" @click="search">搜索</el-button>
        </div>
        <div style="margin-top:10px;">
          <el-table :data="tableData" ref="singleTable" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row)"
                  style="color: #fff;padding-left: 10px; margin-right: -25px;"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="cmSiteName" label="服务站名称"></el-table-column>
            <el-table-column prop="cnStatus" label="状态" width="120" :formatter="formatStatus"></el-table-column>
            <el-table-column prop="nature" label="性质" width="120" :formatter="formatNature"></el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="tablePage.currentPage"
              :page-sizes="[5, 10, 15]"
              :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage.totalResult"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from 'axios'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() //选当前时间之前的时间
        },
      },
      healthReportList: [],
      testCertificateList: [],
      scannedContractList: [],
      insurancePolicyList: [],
      cityList: [],
      areaList: [],
      streetList: [],
      communityList: [],
      siteStatusList: [],
      natureList: [],
      radio: '',
      height: 240,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      visible: false,
      searchData: {
        cmSiteName: '',
      },
      titleName: '',
      form: {},
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        contTel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查联系方式格式' },
        ],
        serviceSiteId: [{ required: true, message: '请选择服务站点', trigger: 'blur' }],
        workYears: [{ pattern: /^[0-9]+?$/, message: '请检查从业年限输入格式' }],
        identityCards: [
          { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请检查身份证号格式' },
        ],
      },
      sexList: [
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },
      ],
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
    this.getDicList()
  },
  methods: {
    ...mapActions([
      'addOrSavePractitioner',
      'detailPractitioner',
      'queryServiceSitePage',
      'getSysDictServe',
      'getCitysServe',
      'getAreasServe',
      'getStreetsServe',
      'getCommunitysServe',
    ]),
    getCurrentRow(row) {
      this.form.serviceSiteId = row.id
    },
    getDicList() {
      this.getSysDictList('workSiteStatus', 'siteStatusList')
      this.getSysDictList('cmOrgNature', 'natureList')
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code,
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    initView() {
      this.getDetail()
      this.getServiceSiteInfo()
    },
    search() {
      this.getServiceSiteInfo()
    },
    handleSizeChange: function(size) {
      this.tablePage.pageSize = size
      this.getServiceSiteInfo()
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.tablePage.currentPage = currentPage
      this.getServiceSiteInfo()
    },
    getServiceSiteInfo() {
      let params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          cmSiteName: this.searchData.cmSiteName,
        },
      }
      let server = 'queryServiceSitePage'
      this[server](params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    getDetail() {
      if (this.routeType == 'add') {
        this.titleName = '添加从业人员'
        this.getCitysList('陕西省')
      } else {
        this.titleName = '修改从业人员'
        this.itemData = JSON.parse(this.$route.query.item)
        //这里调用请求编辑时的详情接口渲染form表单数据

        let params = {
          id: this.itemData.id,
        }
        let data = null
        let server = 'detailPractitioner'
        this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              name: data.name,
              sex: data.sex,
              id: data.id,
              contTel: data.contTel,
              jobTitle: data.jobTitle,
              workYears: data.workYears,
              serviceSiteId: data.serviceSiteId,
              birthDay: data.birthDay,
              identityCards: data.identityCards,
              education: data.education,
              affiliatedUnit: data.affiliatedUnit,
              professionalQualification: data.professionalQualification,
              city: data.city,
              area: data.area,
              street: data.street,
              community: data.community,
              address: data.address,
              healthReport: data.healthReport,
              testCertificate: data.testCertificate,
              scannedContract: data.scannedContract,
              insurancePolicy: data.insurancePolicy,
            }
            this.getCitysList('陕西省')
            if (data.city) {
              this.getAreasList(data.city)
            }
            if (data.area) {
              this.getStreetsList(data.area)
            }
            if (data.street) {
              this.getCommunitysList(data.street)
            }
            if (data.healthReport) {
              this.getFileDetail1(data.healthReport)
            }
            if (data.testCertificate) {
              this.getFileDetail2(data.testCertificate)
            }
            if (data.scannedContract) {
              this.getFileDetail3(data.scannedContract)
            }
            if (data.insurancePolicy) {
              this.getFileDetail4(data.insurancePolicy)
            }
          }
        })
      }
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.healthReportList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.testCertificateList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    getFileDetail3(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.scannedContractList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    getFileDetail4(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.insurancePolicyList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.healthReport = data.join(',')
    },
    uploadSuccess2(data) {
      this.form.testCertificate = data.join(',')
    },
    uploadSuccess3(data) {
      this.form.scannedContract = data.join(',')
    },
    uploadSuccess4(data) {
      this.form.insurancePolicy = data.join(',')
    },
    getCitysList(val) {
      let params = {
        parenName: val,
      }
      this.getCitysServe(params).then((res) => {
        if (res.code == 200) {
          this.cityList = res.data
        }
      })
    },
    cityChange(val) {
      if (val) {
        this.getAreasList(val)
      }
    },
    // 获取区县
    getAreasList(val) {
      let params = {
        parenName: val,
      }
      this.getAreasServe(params).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetsList(val)
      }
    },
    // 获取街道
    getStreetsList(val) {
      let params = {
        parenName: val,
      }
      this.getStreetsServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    streetChange(val) {
      if (val) {
        this.getCommunitysList(val)
      }
    },
    // 获取社区
    getCommunitysList(val) {
      let params = {
        parenName: val,
      }
      this.getCommunitysServe(params).then((res) => {
        if (res.code == 200) {
          this.communityList = res.data
        }
      })
    },
    submitEvent(formName) {
      if (!this.form.serviceSiteId) {
        this.$XModal.message({
          message: '请检查服务站点是否已选择',
          status: 'warning',
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = 'addOrSavePractitioner'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    selectServiceSiteId() {
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    formatStatus(row, column) {
      let msg = row.cnStatus
      for (let i = 0; i < this.siteStatusList.length; i++) {
        if (row.cnStatus === this.siteStatusList[i].dictPidVal) {
          msg = this.siteStatusList[i].dictDesc
        }
      }
      return msg
    },
    formatNature(row, column) {
      let msg = row.nature
      for (let i = 0; i < this.natureList.length; i++) {
        if (row.nature === this.natureList[i].dictPidVal) {
          msg = this.natureList[i].dictDesc
        }
      }
      return msg
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .dialog-footer {
    text-align: center;
  }
}
</style>
